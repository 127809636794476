import * as React from "react"
import styled from 'styled-components'
import Button from './Button'
import Title from "./Title"

const Encomendas = ({ title, data }) => {
    return (
        <EncomendasStyled>
            <div className='wrapper'>
                <Title
                    title={title}
                />
                <section className='buttons'>
                    <Button
                        to={"/equipamentos"}
                        text={data.bottom[0]}
                    />
                    <Button
                        to={"/consumiveis"}
                        text={data.bottom[1]}
                    />
                </section>
            </div>
        </EncomendasStyled>
    )
}

export default Encomendas

const EncomendasStyled = styled.div`
    position:relative;

    .wrapper{
        max-width:1920px;
        margin:0 auto;
        padding:clamp(30px,15vw,80px) 5%;

        .buttons{
            display:flex;
            flex-direction:row;
            justify-content:center;
            gap:5rem;
        }
    }
`