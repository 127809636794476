import React from "react"
import styled from 'styled-components'
import { color, media } from './Styles'
import { getImage } from "gatsby-plugin-image"
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import EncomendaCard from './EncomendaCard'
import Title from "./Title"

const Products = ({ products, category, title }) => {
    const breakpoints = useBreakpoint()

    return (
        <ProductsStyled>
            <div className="background"></div>
            <div className='wrapper'>
                <Title
                    title={title}
                />
                <section className='products'>
                    {products.slice(0, breakpoints.m ? 3 : 6).map((product, key) => {
                        const image = {
                            image: getImage(product.image?.localFile),
                            alt: product.image?.altText || '',
                        }

                        return (
                            category ? // SHOW PRODUCTS FROM CATEGORY
                                product.productCategories.nodes.map(categories => (
                                    category === categories.slug &&
                                    <EncomendaCard
                                        key={key}
                                        product={product}
                                        image={image}
                                    />
                                ))
                                : // SHOW ALL PRODUCTS
                                <EncomendaCard
                                    key={key}
                                    product={product}
                                    image={image}
                                />
                        )
                    })}
                </section>
            </div>
        </ProductsStyled>
    )
}

export default Products

const ProductsStyled = styled.div`
    background-color: #ebebeb;
    
    .wrapper{
        max-width:1920px;
        margin:0 auto;
        padding:clamp(30px,15vw,80px) 5%;
        color:${color.black};

        .wrapper-title{
            max-width: 500px;
            margin: 0 auto 3rem auto;
            border-bottom: 3px solid ${color.yellow};
            padding-bottom:1rem;

            h3{
                font-weight:600;
                text-transform: uppercase;
                font-size: 1.5rem;
                letter-spacing: 0.5rem;
                text-align: center;
            }
        }

        .categories{
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            gap:1rem;
            max-width: 1000px;
            margin: 0 auto;

            ${media.m`
                font-size:0.8rem;
            `}

            .category{
                font-weight:700;
                font-size:1.1rem;
                text-transform: uppercase;
                border-bottom:2px solid ${color.yellow};

                ${media.m`
                    font-size:0.8rem;
                `}
            }
        }
        
        .products{
            display:grid;
            grid-template-columns:repeat(3,1fr);
            gap:3rem 3%;
            max-width: 1400px;
            margin: 0 auto;
            margin-top:3rem;
                

            ${media.l`
                grid-template-columns:repeat(2,1fr);
            `}

            ${media.m`
                grid-template-columns:1fr;
            `}
        }

        .button{
            margin:3rem auto 0 auto;
        }
    }
`