import * as React from "react"

import Layout from "../components/Layout"
import Seo from "../components/Seo"
import HeroSlider from "../components/HeroSlider"
import EncomendasFilter from "../components/EncomendasFilter"
import EncomendasFeatured from "../components/EncomendasFeatured"
import Encomendas from '../components/EncomendasList'
import { connect } from "react-redux"
import Title from "../components/Title"

import { graphql } from "gatsby"
import { useTranslation } from "react-i18next"


const EncomendasPage = ({ products }) => {
    const { t } = useTranslation()


    return (
        <Layout>
            <Seo title="Enomac" />
            <HeroSlider
                slides={[
                    {
                        image: 'encomendas/slider/01.jpg',
                        alt: '',
                        subtitle: 'Rotuladora',
                        title: 'Calvaltec',
                        model: 'AD 800'
                    },
                    {
                        image: 'encomendas/slider/02.jpg',
                        alt: '',
                        subtitle: 'Outro Produto',
                        title: 'Titulo',
                        model: 'Modelo S'
                    },
                    {
                        image: 'encomendas/slider/03.jpg',
                        alt: '',
                        subtitle: 'Mais um produto',
                        title: 'Máquina de X',
                        model: 'XPTO 30'
                    }
                ]}
            />
            <div style={{ background: "#F2AF00", padding: "5rem", textAlign: "center", color: "#fff", fontSize: "50px", letterSpacing: "10px", fontWeight: "500" }}>{t("store", { returnObjects: true }).banner.title}</div>
            <div>
                <Title title={t("store", { returnObjects: true }).news.title} />
            </div>
            <EncomendasFeatured
                title='fefe'
                content={[
                    {
                        image: 'encomendas/featured/1.jpg',
                        alt: 'image',
                        title: t("store", { returnObjects: true }).product.category1.title,
                        text: t("store", { returnObjects: true }).product.category1.subtitle,
                        link: '#',
                        blackText: false,
                        alignRight: true,
                        alignBottom: true,
                    },
                    {
                        image: 'encomendas/featured/2.jpg',
                        alt: 'image',
                        title: t("store", { returnObjects: true }).product.category2.title,
                        text: t("store", { returnObjects: true }).product.category2.subtitle,
                        link: '#',
                        blackText: false,
                        alignRight: true,
                        alignBottom: false,
                    },
                    {
                        image: 'encomendas/featured/3.jpg',
                        alt: 'image',
                        title: t("store", { returnObjects: true }).product.category3.title,
                        text: t("store", { returnObjects: true }).product.category3.subtitle,
                        link: '#',
                        blackText: true,
                        alignRight: false,
                        alignBottom: false,
                    },
                    {
                        image: 'encomendas/featured/1.jpg',
                        alt: 'image',
                        title: t("store", { returnObjects: true }).product.category4.title,
                        text: t("store", { returnObjects: true }).product.category4.subtitle,
                        link: '#',
                        blackText: false,
                        alignRight: false,
                        alignBottom: false,
                    }
                ]}
            />
            <EncomendasFilter
                title={t("store", { returnObjects: true }).banner.text}
                data={t("store", { returnObjects: true })}
            />
            <Encomendas
                products={products}
                title={t("store", { returnObjects: true }).bottom[2]}
                data={t("store", { returnObjects: true })}
            />
        </Layout>
    )
}

export default connect(
    state => ({
        blog: state.app.blog,
        products: state.app.products,
        categories: state.app.categories
    }),
    null
)(EncomendasPage)

export const pageQuery = graphql`
  query($language: String!) {
    locales: allLocale(filter: {ns: {in: ["home", "store"]}, language: {eq: $language}}) {
        edges {
          node {
            ns
            data
            language
          }
        }
    }
  }
`