import React from "react"
import styled from 'styled-components'
import { Link } from 'gatsby-plugin-react-i18next'
import { color, media } from './Styles'
import { GatsbyImage } from "gatsby-plugin-image"

const Products = ({ product, image }) => {

    return (
        <ProductStyled>
            <Link to={'/products' + product.link} itemProp='url' className='product'>
                {image?.image &&
                    <div className='image-container'>
                        <GatsbyImage image={image.image} alt={image.alt || ''} className='image' />
                    </div>
                }
                <div className='content'>
                    <span className='name'>{product.name}</span>
                    <span
                        className='description'
                        dangerouslySetInnerHTML={{ __html: 
                            product.shortDescription.length < 100 ? 
                                product.shortDescription 
                            : 
                                product.shortDescription.slice(0, 100) + '...' 
                        }}>
                    </span>
                    {/* {product.new &&
                        <span className='new'>
                            Novo
                        </span>
                    } */}
                </div>
            </Link>
        </ProductStyled>
    )
}

export default Products

const ProductStyled = styled.article`
    .product{
        display:flex;
        flex-direction:column;
        align-items:center;
        height:100%;

        .image-container{
            width:100%;
            text-align:center;
            background-color: ${color.white};

            .image{
                width:350px;
                height:350px;

                @media only screen and (max-width:1300px){
                    width:300px;
                    height:300px;
                }

                ${media.xl`
                    width:250px;
                    height:250px;
                `}

                img{
                    object-fit:contain !important;
                }
            }
        }        

        .content{
            position:relative;
            display:flex;
            flex-direction:column;
            background-color:#e3e3e3;
            width:100%;
            height:100%;
            padding: 5%;
            box-sizing: border-box;

            .name{
                font-weight:500;
                text-transform:uppercase;
                font-size:1.3rem;
            }

            .description{
                font-weight:500;
                font-size:1.1rem;
                margin-top:0.5rem;
            }

            /* .new{
                position:absolute;
                top: 50%;
                transform: translateY(-50%);
                right:-6rem;
                width:60px;
                height:60px;
                background:${color.yellow};
                color:${color.white};
                z-index:1;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                font-weight:800;
                text-transform:uppercase;
                font-size:0.9rem;

                ${media.xl`
                    position:relative;
                    transform:none;
                    top:0;
                    right:0;
                    margin: 1rem auto 0 auto;
                `}
            } */
        }
    }
`