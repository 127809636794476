import * as React from "react"
import styled from 'styled-components'
import { color, media } from './Styles'
import { Image } from './Images'
import { Link } from 'gatsby'

const Encomendas = ({ content }) => {
    return (
        <EncomendasStyled>
            <div className='background'></div>
            <div className='wrapper'>
                {content.map((content, key) => (
                    <Link 
                        to={content.link} 
                        className={`container ${content.alignRight ? 'right' : ''} ${content.alignBottom ? 'bottom' : ''} ${content.blackText ? 'black' : 'white'}`} 
                        key={key}
                    >
                        <Image src={content.image} alt={content.alt} />
                        <div className='content'>
                            {content.title &&
                                <h1 dangerouslySetInnerHTML={{ __html: content.title }}></h1>
                            }
                            {content.text &&
                                <span className='text' dangerouslySetInnerHTML={{ __html: content.text }}></span>
                            }
                        </div>
                    </Link>
                ))}
            </div>
        </EncomendasStyled>
    )
}

export default Encomendas

const EncomendasStyled = styled.div`
    position:relative;
    overflow:hidden;
    
    .background{
        position: absolute;
        background-color: #ebebeb;
        border-radius: 50%;
        top: 50%;
        left: -2.5%;
        width: 105%;
        height: 100%;
        z-index: -1;

        ${media.m`
            height:50%;
            top:75%;
        `}
    }

    .wrapper{
        max-width:1920px;
        margin:0 auto;
        padding:clamp(30px,15vw,80px) 5%;
        display:grid;
        grid-template-columns:repeat(2,1fr);
        gap:2rem;

        ${media.m`
            grid-template-columns:1fr;
        `}

        .container{
            position:relative;  
            height: 35vh;
            max-height: 400px; 
            padding: 5%;
            box-sizing: border-box;        

            .image{
                position:absolute;
                width:100%;
                height:100%;
                top:0;
                left:0;
                z-index:-1; 
                border:1px solid #d5d5d5; 

                :before{
                    content:'';
                    position:absolute;
                    top:0;
                    left:0;
                    width:100%;
                    height:100%;
                    background-color:#000;
                    opacity: 15%;
                    z-index: 1;
                }
            } 

            .content{
                max-width:75%;

                ${media.xl`
                    max-width:85%;
                `}

                ${media.m`
                    max-width:90%;
                `}

                ${media.s`
                    max-width:100%;
                `}

                h1{
                    font-weight: 800;
                    font-size: 3rem;
                    text-transform:uppercase;
                    letter-spacing:0.1rem;        

                    ${media.xl`
                        font-size:2rem;
                    `}
                }

                .text{
                    font-weight:500;
                    font-size:1.5rem;

                    ${media.xl`
                        font-size:1.2rem;
                    `}
                }
            }   
        }   
        
        .right{
            text-align: right;
            display: flex;
            justify-content: end;
        }

        .bottom{
            display: flex;
            align-items: end;
        }

        .black{
            color: ${color.black}
        }

        .white{
            color: ${color.white}
        }
    }
`