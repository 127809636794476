import * as React from "react"
import styled from 'styled-components'
import { color, media } from './Styles'
import { Image } from './Images'
import Slider from "react-slick"

import LeftArrow from '../images/encomendas/slider/arrow-border-prev.svg'
import RightArrow from '../images/encomendas/slider/arrow-border-next.svg'

const Hero = ({ slides }) => {

    function NextArrow(props) {
        const { className, onClick, onKeyUp } = props
        return (
            <div className={className} onClick={onClick} onKeyUp={onKeyUp} role='button' tabIndex='0'>
                <RightArrow />
            </div>
        )
    }

    function PrevArrow(props) {
        const { className, onClick, onKeyUp } = props
        return (
            <div className={className} onClick={onClick} onKeyUp={onKeyUp} role='button' tabIndex='0'>
                <LeftArrow />
            </div>
        )
    }

    const sliderSettings = {
        arrows: true,
        infinite: false,
        focusOnSelect: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        speed: 500,
        pauseOnHover: false,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
    }


    return (
        <HeroStyled>
            <Slider {...sliderSettings}>
                {slides.map((slide, key) => (
                    <div className="container" key={key}>
                        <Image src={slide.image} alt={slide.alt} />
                        <div className='wrapper'>
                            {slide.subtitle &&
                                <span className='subtitle'>{slide.subtitle}</span>
                            }
                            {slide.title &&
                                <h1>{slide.title}</h1>
                            }
                            {slide.model &&
                                <span className='model'>
                                    {slide.model}
                                </span>
                            }
                        </div>
                    </div>
                ))}
            </Slider>
        </HeroStyled>
    )
}

export default Hero

const HeroStyled = styled.div`
    position:relative;
    overflow:hidden;

    .slick-slider{
        overflow:hidden;
        position:relative;

        .slick-arrow{
            position: absolute;
            top: 50%;
            transform:translateY(-50%);
            cursor:pointer;
            z-index:1;

            svg{
                max-width:40px;

                ${media.l`
                    max-width:20px;
                `}
            }

            .cls-2{
                fill:#000;
            }
        }

        .slick-prev{
            left:1.5rem;
        }

        .slick-next{
            right:1.5rem;
        }

        .slick-disabled{
            opacity:0;
            transition: opacity 0.3s;
        }

        .slick-track{
            display:flex;
        }
    }    

    .container{
        position:relative;

        .image{
            position:absolute;
            width:100%;
            top:0;
            left:0;
            z-index:-1;  
            brightness(0.5); 

            :before{
                content:'';
                position:absolute;
                top:0;
                left:0;
                width:100%;
                height:100%;
                background-color:#fff;
                opacity: 25%;
                z-index: 1;
            }
        }

        .wrapper{
            max-width:1920px;
            margin:0 auto;
            padding:clamp(30px,15vw,80px) 5% clamp(30px,15vw,80px) 7%;
            color:${color.black};
            display:flex;
            flex-direction:column;
            height:40vh;

            ${media.m`
                height:20vh;
                gap:0.5rem;
            `}

            .subtitle{
                font-weight:500;
                font-size:1.8rem;

                ${media.m`
                    font-size:1.3rem;
                `}
            }

            h1{
                font-weight: 800;
                font-size: 5rem;
                text-transform:uppercase;
                letter-spacing:0.1rem;        

                ${media.m`
                    font-size:2.5rem;
                `}
            }

            .model{
                font-weight:600;
                font-size: 3rem;

                ${media.s`
                    font-size:1.5rem;
                `}
            }
        }
    }

    
`